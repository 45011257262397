import { ITypoColor } from "components/design_system/Typography";
import { IconProps } from "..";
import classes from "../classes.module.scss";
import classNames from "classnames";

export default function ShoppingBag({
    color = ITypoColor.BLACK,
    onClick,
    size = "small",
    className = "",
}: IconProps) {
    switch (size) {
        case "large":
            return (
                <svg
                    width="68"
                    height="67"
                    viewBox="0 0 68 67"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={classNames(classes["icon"], className)}
                    onClick={onClick}
                >
                    <path
                        d="M44.4693 29.3125V16.75C44.4693 10.9683 39.7823 6.28125 34.0005 6.28125C28.2188 6.28125 23.5318 10.9683 23.5318 16.75V29.3125M55.234 23.7493L58.7603 57.2494C58.9555 59.1037 57.5016 60.7188 55.637 60.7188H12.3641C10.4995 60.7188 9.04554 59.1037 9.24074 57.2494L12.7671 23.7493C12.9353 22.1509 14.2832 20.9375 15.8904 20.9375H52.1107C53.7179 20.9375 55.0658 22.1509 55.234 23.7493ZM24.5787 29.3125C24.5787 29.8907 24.11 30.3594 23.5318 30.3594C22.9536 30.3594 22.4849 29.8907 22.4849 29.3125C22.4849 28.7343 22.9536 28.2656 23.5318 28.2656C24.11 28.2656 24.5787 28.7343 24.5787 29.3125ZM45.5162 29.3125C45.5162 29.8907 45.0475 30.3594 44.4693 30.3594C43.8911 30.3594 43.4224 29.8907 43.4224 29.3125C43.4224 28.7343 43.8911 28.2656 44.4693 28.2656C45.0475 28.2656 45.5162 28.7343 45.5162 29.3125Z"
                        stroke={`var(${color})`}
                        strokeWidth="5.58333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        default:
            return (
                <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={classes["icon"]}
                    onClick={onClick}
                >
                    <path
                        d="M11.8129 7.875V4.5C11.8129 2.9467 10.5537 1.6875 9.00036 1.6875C7.44706 1.6875 6.18786 2.9467 6.18786 4.5V7.875M14.7049 6.38042L15.6523 15.3804C15.7047 15.8786 15.3141 16.3125 14.8131 16.3125H3.18759C2.68665 16.3125 2.29604 15.8786 2.34848 15.3804L3.29585 6.38042C3.34105 5.951 3.70316 5.625 4.13496 5.625H13.8658C14.2976 5.625 14.6597 5.951 14.7049 6.38042ZM6.46911 7.875C6.46911 8.03033 6.34319 8.15625 6.18786 8.15625C6.03253 8.15625 5.90661 8.03033 5.90661 7.875C5.90661 7.71967 6.03253 7.59375 6.18786 7.59375C6.34319 7.59375 6.46911 7.71967 6.46911 7.875ZM12.0941 7.875C12.0941 8.03033 11.9682 8.15625 11.8129 8.15625C11.6575 8.15625 11.5316 8.03033 11.5316 7.875C11.5316 7.71967 11.6575 7.59375 11.8129 7.59375C11.9682 7.59375 12.0941 7.71967 12.0941 7.875Z"
                        stroke={`var(${color})`}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
    }
}
