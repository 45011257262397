import EventEmitter from "services/EventEmitter";
import LocalStorageService from "services/LocalStorageService";
import { BasketItem, Item } from "types/Item";
// import products from "../assets/products.json";

export default class BasketItemsService {
	public static readonly instance = new this();
	protected readonly event = new EventEmitter();

	private constructor() {
		// Add test products to basket
		// this.setBasketItems(products.filter((item) => item.quantite_en_stock >= 20).map((item) => ({ ...item, quantity: 2 })));
	}

	public getBasketItems() {
		return LocalStorageService.getInstance().items.basket.get() ?? [];
	}

	public setBasketItems(basketItems: BasketItem[]) {
		LocalStorageService.getInstance().items.basket.set(basketItems);
		this.event.emit("change", this.getBasketItems());
	}

	public addBasketItem(itemToAdd: Item, quantity: number) {
		const basketItems = this.getBasketItems();
		const index = basketItems.findIndex((item) => item.sku === itemToAdd.sku);
		if (index !== -1) {
			basketItems[index].quantity += quantity;
			LocalStorageService.getInstance().items.basket.set(basketItems);
			this.event.emit("change", this.getBasketItems());
			return;
		}

		basketItems.push({
			...itemToAdd,
			quantity,
		});

		LocalStorageService.getInstance().items.basket.set(basketItems);
		this.event.emit("change", this.getBasketItems());
	}

	public removeBasketItem(sku: string) {
		const basketItems = this.getBasketItems();
		const index = basketItems.findIndex((item) => item.sku === sku);
		if (index === -1) {
			return;
		}
		basketItems.splice(index, 1);
		LocalStorageService.getInstance().items.basket.set(basketItems);
		this.event.emit("change", this.getBasketItems());
	}

	public updateBasketItemQuantity(quantity: number, sku: string) {
		const basketItems = this.getBasketItems();
		const index = basketItems.findIndex((item) => item.sku === sku);
		if (index === -1) {
			return;
		}
		basketItems[index].quantity = quantity;
		LocalStorageService.getInstance().items.basket.set(basketItems);
		this.event.emit("change", this.getBasketItems());
	}

	public clearBasketItems() {
		LocalStorageService.getInstance().items.basket.delete();
		this.event.emit("change", this.getBasketItems());
	}

	public onChange(callback: (items: BasketItem[]) => void) {
		this.event.on("change", callback);
		return () => this.event.off("change", callback);
	}
}
