import React, { useEffect } from "react";

import { DetectedObject } from "components/pages/Scan";
import products from "../../../assets/products.json";
import classes from "./classes.module.scss";
import PointObject from "./PointObject";
import Tooltip from "./Tooltip";
import { Point } from "components/WebcamAi";
import HistoryService from "services/HistoryService";

type IProps = {
	points: Point[];
};

type IStaticPointData = { lastClosestPointUpdatedTime: number; previousClosestPoint: Point | null };

export default function PointDisplayer({ points }: IProps) {
	const [selectedObject, setSelectedObject] = React.useState<DetectedObject | null>(null);
	const timeout = React.useRef<number | null>();

	const staticPointData = React.useRef<IStaticPointData>({
		lastClosestPointUpdatedTime: 0,
		previousClosestPoint: null,
	});

	const onPointClick = React.useCallback((obj: DetectedObject) => {
		HistoryService.instance.add(obj.item, "scan");
		setSelectedObject(obj);
	}, []);

	const onCloseTooltip = React.useCallback(() => {
		timeout.current && window.clearTimeout(timeout.current);
		timeout.current = null;
		setSelectedObject(null);
	}, []);

	const getPointsToObjectCb = React.useCallback(() => getPointsToObject(points, selectedObject, staticPointData), [points, selectedObject]);

	/**
	 * Close the tooltip after 3 seconds if the object is not detected anymore
	 */
	useEffect(() => {
		if (selectedObject && !points.find((point) => point.name === selectedObject.item.sku) && !timeout.current) {
			timeout.current = window.setTimeout(() => {
				setSelectedObject(null);
				timeout.current = null;
			}, 3000);
		}
	}, [points, selectedObject]);

	return (
		<div className={classes["root"]}>
			{getPointsToObjectCb().map((obj) => (
				<PointObject key={obj.id} object={obj} onPointClick={onPointClick} selected={selectedObject?.id === obj.id} showHand={obj.showHand} />
			))}
			{selectedObject && <Tooltip detectedObject={selectedObject} onCloseTooltip={onCloseTooltip} />}
		</div>
	);
}

function findClosestPoint(points: Point[], centerX: number, centerY: number): Point | null {
	if (points.length === 0) {
		return null;
	}

	let closestPoint = points[0];
	let minDistance = calculateDistance(centerX, centerY, closestPoint.x, closestPoint.y);

	for (let i = 1; i < points.length; i++) {
		const distance = calculateDistance(centerX, centerY, points[i].x, points[i].y);
		if (distance < minDistance) {
			minDistance = distance;
			closestPoint = points[i];
		}
	}

	return closestPoint;
}

function calculateDistance(x1: number, y1: number, x2: number, y2: number): number {
	return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
}

function getPointsToObject(points: Point[], selectedObject: DetectedObject | null, staticPointData: React.MutableRefObject<IStaticPointData>) {
	const detectedObjects: DetectedObject[] = [];
	const centerX = window.innerWidth / 2;
	const centerY = window.innerHeight / 2;
	const currentTime = new Date().getTime();
	let closestPoint: Point | null = staticPointData.current.previousClosestPoint;
	const isClosestPointStillDetected = points.find((point) => point.id === closestPoint?.id);

	if (
		!closestPoint ||
		!isClosestPointStillDetected ||
		(isClosestPointStillDetected && currentTime - staticPointData.current.lastClosestPointUpdatedTime >= 1000)
	) {
		closestPoint = findClosestPoint(points, centerX, centerY);
		staticPointData.current.lastClosestPointUpdatedTime = currentTime;
		staticPointData.current.previousClosestPoint = closestPoint;
	}

	points.forEach((point) => {
		const item = products.find((product) => product.sku === point.name);
		if (!item) return;
		detectedObjects.push({ ...point, item, showHand: closestPoint?.id === point.id });
	});

	return detectedObjects;
}
