import React, { useEffect, useState } from "react";

import LangSwitcher from "components/design_system/LangSwitcher";
import AccorLoader from "components/elements/AccorLoader";
import WebcamAi, { IFacingModeType, Point } from "components/WebcamAi";
import { Item } from "types/Item";

import classes from "./classes.module.scss";
import PointDisplayer from "components/materials/PointDisplayer";
import ItemDrawerStore from "stores/ItemDrawerStore";

export type DetectedObject = Point & {
	item: Item;
	showHand?: boolean;
};

export default React.memo(function Scan() {
	const [facingMode] = React.useState<IFacingModeType>("environment");

	const [points, setPoints] = useState<Point[]>([]);
	const [progress, setProgress] = useState<number>(100);
	const [isReady, setIsReady] = useState<boolean>(false);

	const [pauseDetection, setPauseDetection] = useState<boolean>(false);

	const onDetect = React.useCallback((points: Point[]) => setPoints(points), []);

	useEffect(() => ItemDrawerStore.instance.onDrawerOpenChange(setPauseDetection), []);
	return (
		<div className={classes["root"]}>
			<WebcamAi facingMode={facingMode} onDetect={onDetect} setProgress={setProgress} isPaused={pauseDetection} onReady={setIsReady} />
			<PointDisplayer points={points} />

			<div className={classes["lang_switcher"]}>
				<LangSwitcher />
			</div>
			{!isReady && (
				<div className={classes["loader"]}>
					<AccorLoader progress={progress} />
				</div>
			)}
		</div>
	);
});
