import { DetectedObject } from "components/pages/Scan";
import classes from "./classes.module.scss";
import { useCallback } from "react";

type IProps = {
	object: DetectedObject;
	selected: boolean;
	onPointClick: (object: DetectedObject) => void;
	showHand?: boolean;
};
export default function PointObject({ object, onPointClick, selected, showHand }: IProps) {
	const onClickHandler = useCallback(
		(e: React.MouseEvent<HTMLDivElement>) => {
			e.stopPropagation();
			onPointClick(object);
		},
		[object, onPointClick],
	);

	return (
		<div className={classes["root"]} style={{ left: object.x, top: object.y }}>
			<div className={classes["point-container"]} onClick={onClickHandler}>
				<div className={classes["point"]} data-selected={selected}>
					<div className={classes["point-inner"]}></div>
					{showHand && <div className={classes["hand"]}>P</div>}
				</div>
			</div>
		</div>
	);
}
