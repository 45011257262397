import AccorLogo from "components/design_system/Icons/AccorLogo";

import classes from "./classes.module.scss";
import React, { useEffect, useState } from "react";

type IProps = {
	progress: number;
};
const size = 128;
const strokeWidth = 8;
const center = size / 2;
const radius = center - strokeWidth;
const arcLength = 2 * 3.14 * radius;

export default function AccorLoader(props: IProps) {
	const { progress } = props;
	const [arcOffset, setArcOffset] = useState<number>(0);
	useEffect(() => setArcOffset(arcLength * ((100 - progress) / 100)), [progress]);

	return (
		<div className={classes["root"]} data-animation={(progress === 100).toString()}>
			<svg className={classes["svg-indicator"]}>
				<circle className={classes["svg-indicator-track"]} strokeWidth={strokeWidth} fill="transparent" cx={center} cy={center} r={radius} />
				<circle
					className={classes["svg-indicator-indication"]}
					strokeDashoffset={arcOffset}
					strokeWidth={strokeWidth}
					fill="transparent"
					cx={center}
					cy={center}
					r={radius}
				/>
			</svg>

			<AccorLogo className={classes["logo"]} />
		</div>
	);
}
