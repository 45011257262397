import { useEffect, useState } from "react";
import TabElement from "./TabElement";
import classes from "./classes.module.scss";
import React from "react";

export type Tab = {
    name: string;
    active: boolean;
    ref: React.RefObject<HTMLDivElement>;
};

type IProps = {
    defaultTabs: string[];
    onChange?: (name: string) => void;
};

export default function TabsContainer({ defaultTabs, onChange }: IProps) {
    const [tabs, setTabs] = useState<Tab[]>([]);
    const [barStyle, setBarStyle] = useState<
        React.HTMLAttributes<HTMLDivElement>["style"]
    >({});
    const [activeTab, setActiveTab] = useState<Tab | undefined>();

    const containerRef = React.createRef<HTMLDivElement>();
    const handleTabClicked = (tabName: string) => {
        onChange && onChange(tabName);
        setTabs(
            tabs.map((tab) => {
                if (tab.name === tabName) setActiveTab(tab);
                return {
                    ...tab,
                    active: tab.name === tabName,
                };
            })
        );
    };

    useEffect(() => {
        setTabs(
            defaultTabs.map((tabName, index) => {
                const tab = {
                    name: tabName,
                    active: index === 0,
                    ref: React.createRef<HTMLDivElement>(),
                };
                if (index === 0) setActiveTab(tab);
                return tab;
            })
        );
    }, [defaultTabs]);

    useEffect(() => {
        const divRef = activeTab?.ref.current;
        if (!divRef || !containerRef.current) return;
        setBarStyle({
            width: divRef?.clientWidth! * 0.6 ?? 0,
            left:
                divRef?.getBoundingClientRect()?.left! -
                    containerRef.current?.getBoundingClientRect().left! ?? 0,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    return (
        <div className={classes["root"]} ref={containerRef}>
            {tabs.map((tab) => {
                return (
                    <TabElement
                        innerRef={tab.ref}
                        tab={tab}
                        key={tab.name}
                        handleOnClick={handleTabClicked}
                    />
                );
            })}
            <div className={classes["active-bar"]} style={barStyle} />
        </div>
    );
}
