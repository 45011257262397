import Typography, { ITypo, ITypoColor } from "components/design_system/Typography";
import React from "react";
import classes from "./classes.module.scss";

type IProps = {
	lang: string;
	onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
	onLoad: (ref: HTMLDivElement|null)=>void
};
export default function LangSwitcherButton({ lang, onClick, onLoad }: IProps) {
	const ref = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		onLoad(ref.current);
	}, [onLoad]);

	return (
		<div className={classes["root"]} data-link={lang} onClick={onClick} ref={ref}>
			<div className={classes["label"]}>
				<Typography typo={ITypo.TEXT_XS_REGULAR} color={ITypoColor.BLACK}>
					{lang.toUpperCase()}
				</Typography>
			</div>
		</div>
	);
}
