import classNames from "classnames";
import { IconProps } from "..";
import classes from "../classes.module.scss";

export default function Minus(props: IconProps) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={props.onClick}
            className={classNames(classes["icon"], props.className)}
        >
            <path
                d="M5 12H19"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
