import classNames from "classnames";
import { IconProps } from "..";
import classes from "../classes.module.scss";
import { ITypoColor } from "components/design_system/Typography";

export default function Book({ color = ITypoColor.BLACK, size = "small", className, onClick }: IconProps) {
	switch (size) {
		case "large":
			return (
				<svg width="68" height="67" viewBox="0 0 68 67" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M34 16.8664C29.5549 12.8879 23.685 10.4688 17.25 10.4688C14.3134 10.4688 11.4945 10.9725 8.875 11.8984V51.6797C11.4945 50.7538 14.3134 50.25 17.25 50.25C23.685 50.25 29.5549 52.6692 34 56.6476M34 16.8664C38.4451 12.8879 44.315 10.4688 50.75 10.4688C53.6866 10.4688 56.5055 10.9725 59.125 11.8984V51.6797C56.5055 50.7538 53.6866 50.25 50.75 50.25C44.315 50.25 38.4451 52.6692 34 56.6476M34 16.8664V56.6476"
						stroke={`var(${color})`}
						strokeWidth="5.58333"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			);
		default:
			return (
				<svg
					width="18"
					height="18"
					viewBox="0 0 18 18"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className={classNames(classes["icon"], className)}
					onClick={onClick}>
					<path
						d="M9 4.53126C7.8058 3.46242 6.2288 2.8125 4.5 2.8125C3.71107 2.8125 2.95375 2.94785 2.25 3.19659V13.8841C2.95375 13.6353 3.71107 13.5 4.5 13.5C6.2288 13.5 7.8058 14.1499 9 15.2188M9 4.53126C10.1942 3.46242 11.7712 2.8125 13.5 2.8125C14.2889 2.8125 15.0462 2.94785 15.75 3.19659V13.8841C15.0462 13.6353 14.2889 13.5 13.5 13.5C11.7712 13.5 10.1942 14.1499 9 15.2188M9 4.53126V15.2188"
						stroke={`var(${color})`}
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			);
	}
}
