import { BrowserRouter, Route, Routes } from "react-router-dom";
import ModuleConfig from "configs/Module";
import Scan from "components/pages/Scan";
import Search from "components/pages/Search";
import History from "components/pages/History";
import Basket from "components/pages/Basket";
import DefaultTemplate from "components/pageTemplates/DefaultTemplate";

export default function Router() {
	const pageModules = ModuleConfig.getInstance().get().modules.pages;
	return (
		<BrowserRouter basename="/">
			<Routes>
				<Route path="/" element={<DefaultTemplate />}>
					<Route path="/" element={<Scan />} />
					<Route path={pageModules.Scan.props.path} element={<Scan />} />
					<Route path={pageModules.History.props.path} element={<History />} />
					<Route path={pageModules.Search.props.path} element={<Search />} />
					<Route path={pageModules.Basket.props.path} element={<Basket />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);

	//return <RouterProvider router={router}></RouterProvider>;
}
