import Typography, { ITypo } from "components/design_system/Typography";
import classes from "./classes.module.scss";
import Cross from "components/design_system/Icons/Cross";
import { useCallback } from "react";

type IProps = {
	title: string;
	children: JSX.Element;
	isOpen: boolean;
	closeModal: () => void;
	closable?: boolean;
};

export default function Modal(props: IProps) {
	const { title, children, isOpen, closeModal, closable = true } = props;

	const handleClose = useCallback(() => {
		if (closable) closeModal();
	}, [closable, closeModal]);

	return (
		<div className={classes.root} data-open={isOpen.toString()}>
			<div className={classes.background} onClick={handleClose}></div>
			<div className={classes.modal}>
				<div className={classes.title} data-closable={closable.toString()}>
					<Typography typo={ITypo.TEXT_LG_SEMIBOLD}>{title}</Typography>
					{closable && <Cross onClick={handleClose} />}
				</div>
				<div className={classes.separator} />
				<div className={classes.body}>{children}</div>
			</div>
		</div>
	);
}
