import Router from "router";
import "themes/constants.module.scss";
import "themes/modes.scss";
import "themes/fonts.scss";
import { useOrientation } from "@uidotdev/usehooks";
import WrongOrientation from "components/pages/WrongOrientation";

const { REACT_APP_API_URL, REACT_APP_API_KEY } = process.env;

if (!REACT_APP_API_URL) {
	throw new Error("REACT_APP_API_URL is not defined");
}
if (!REACT_APP_API_KEY) {
	throw new Error("REACT_APP_API_KEY is not defined");
}

let deviceType: "mobile" | "pad" | "desktop" = "desktop";

if (navigator.userAgent.match(/iPad/i)) {
	deviceType = "pad";
} else if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i)) {
	deviceType = "mobile";
}

document.body.setAttribute("data-device-type", deviceType);

function App() {
	const { type } = useOrientation();
	const isWrongOrientation = (type === "landscape-primary" || type === "landscape-secondary")

	return (
		<div className="App">
			{!isWrongOrientation && <Router />}
			{isWrongOrientation && <WrongOrientation />}
		</div>
	);
}

export default App;
