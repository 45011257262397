import React from "react";
import I18nStore, { ILngType } from "stores/I18nStore";

type IVars = { [key: string]: string };

type IProps = {
	children?: React.ReactNode;
	map?: string | string[];
	vars?: IVars;
	content?: (trads: string[]) => React.ReactNode;
};

/**
 * @example: usage <I18n map="menu_status.bla" vars={{myStringValue: this.state.myStringValue}}/>
 */
export default function I18n({ children, map, vars, content }: IProps): JSX.Element {
	const [, setAsset] = React.useState<ILngType>(I18nStore.getInstance().asset);

	React.useEffect(
		() =>
			I18nStore.getInstance().onChange((asset) => {
				setAsset(asset);
			}),
		[],
	);

	if (children) return <>{children}</>;

	const trads = map ? I18nStore.getInstance().getTranslations(map, vars) : [];

	if (content) return <>{content(trads)}</>;

	return <>{trads.join(" ")}</>;
}
