"use client";

import EventEmitter from "services/EventEmitter";
import LocalStorageService from "services/LocalStorageService";
import { Item } from "types/Item";
import History from "./api/History";
import { v4 as uuidv4 } from "uuid";

export type HistoryItem = Item & { date: number; origin: "scan" | "catalog" };
export default class HistoryService {
	public static readonly instance = new this();
	protected readonly event = new EventEmitter();

	private constructor() {}

	public get() {
		return (
			LocalStorageService.getInstance()
				.items.history.get()
				?.filter((item) => item.origin === "scan")
				?.sort((itemA, itemB) => {
					return itemA.date < itemB.date ? 1 : itemA.date > itemB.date ? -1 : 0;
				}) ?? []
		);
	}

	public add(item: Item, origin: "scan" | "catalog") {
		const historyItems = this.get();
		// if item is the same than the last one, do nothing
		if (historyItems[0]?.sku === item.sku) {
			return;
		}

		historyItems.push({ ...item, date: Date.now(), origin });
		LocalStorageService.getInstance().items.history.set(historyItems);
		let sessionId = LocalStorageService.getInstance().session.get();
		if (!sessionId) {
			sessionId = uuidv4();
			LocalStorageService.getInstance().session.set(sessionId);
		}
		History.getInstance().create(item, sessionId, origin);
		this.event.emit("change", this.get());
	}

	public remote(sku: string) {
		const historyItems = this.get().filter((item) => item.sku !== sku);
		LocalStorageService.getInstance().items.history.set(historyItems);
		this.event.emit("change", this.get());
	}

	public clear() {
		LocalStorageService.getInstance().items.history.delete();
		this.event.emit("change", this.get());
	}

	public onChange(callback: (items: HistoryItem[]) => void) {
		this.event.on("change", callback);
		return () => this.event.off("change", callback);
	}
}
