import classNames from "classnames";
import { IconProps } from "..";
import classes from "../classes.module.scss";
import { ITypoColor } from "components/design_system/Typography";

export default function AccorLogo({ color = ITypoColor.WHITE, className, onClick }: IconProps) {
	return (
		<svg
			width="60"
			height="65"
			viewBox="0 0 60 65"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={classNames(classes["icon"], className)}
			onClick={onClick}>
			<path
				d="M24.6738 20.9132L32.3139 36.7542C33.8699 35.7341 36.0082 33.2516 38.7102 33.2516C41.7196 33.2516 42.274 35.1612 44.6033 35.6969C44.8036 35.6969 44.8316 35.8878 44.6965 35.9531C44.2632 36.1254 43.7927 36.1859 43.3315 36.1347C41.6218 35.9065 41.4215 34.8818 39.3018 34.9237C36.9026 34.9237 35.4818 36.8334 33.1804 38.5521C30.8791 40.2708 26.4114 43.3262 23.7374 44.3556C24.9021 36.1254 16.8613 27.8486 14.7789 25.748C14.6951 25.6641 14.6624 25.6501 14.6531 25.8039C14.355 28.7708 13.6422 31.6866 12.5335 34.4533C11.5784 36.6284 10.2601 38.7989 8.7134 41.8171C4.45543 50.2104 8.61557 54.7936 9.20256 56.8477C9.10473 58.0866 7.88417 59.7122 5.7645 61.1747C4.14331 62.1808 2.44292 63.0611 0.681965 63.8016C0.0996388 64.0765 -0.0447791 64.1929 0.0111241 64.3466C0.0670274 64.5003 0.467668 64.5655 1.01273 64.5096C3.30942 64.1184 5.5735 63.5455 7.78168 62.8002C11.8486 61.4169 15.6314 59.3023 18.9344 56.5542C24.9626 51.617 28.3494 44.1413 34.2892 40.9042L45.1391 63.592H60L29.4395 0C22.4563 9.47383 21.4826 14.3225 24.6645 20.9225"
				fill={`var(${color})`}
			/>
		</svg>
	);
}
