import classNames from "classnames";
import { IconProps } from "..";
import classes from "../classes.module.scss";

export default function Camera(props: IconProps) {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classNames(classes["icon"], props.className)}
            onClick={props.onClick}
        >
            <path
                d="M5.12016 4.63117C4.84935 5.06515 4.39596 5.35048 3.8895 5.42246C3.60491 5.4629 3.3214 5.50667 3.039 5.55373C2.24934 5.68531 1.6875 6.37997 1.6875 7.18051V13.5C1.6875 14.432 2.44302 15.1875 3.375 15.1875H14.625C15.557 15.1875 16.3125 14.432 16.3125 13.5V7.18052C16.3125 6.37998 15.7507 5.68532 14.961 5.55373C14.6786 5.50667 14.3951 5.4629 14.1105 5.42246C13.604 5.35049 13.1506 5.06516 12.8798 4.63118L12.2639 3.64417C11.9801 3.18927 11.497 2.89379 10.9616 2.86504C10.3122 2.83016 9.65815 2.8125 9 2.8125C8.34185 2.8125 7.68783 2.83016 7.03838 2.86504C6.50295 2.89379 6.01993 3.18927 5.73607 3.64417L5.12016 4.63117Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.375 9.5625C12.375 11.4265 10.864 12.9375 9 12.9375C7.13604 12.9375 5.625 11.4265 5.625 9.5625C5.625 7.69854 7.13604 6.1875 9 6.1875C10.864 6.1875 12.375 7.69854 12.375 9.5625Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.0625 7.875H14.0681V7.88063H14.0625V7.875Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
