import SearchBar from "components/design_system/SearchBar";
import classes from "./classes.module.scss";

import Typography, { ITypo } from "components/design_system/Typography";
import { useCallback, useEffect, useState } from "react";
import { Item } from "types/Item";
import SearchItem from "components/design_system/SearchItem";
import ItemDrawerStore from "stores/ItemDrawerStore";
import ProductsStore from "stores/ProductsStore";
import I18n from "components/materials/I18n";
import { useTranslate } from "hooks/useTranslate";
import Radio from "components/design_system/Radio";
import I18nStore from "stores/I18nStore";

export type SearchItemObj = {
	item: Item;
	subItems: Item[];
};

export default function Search() {
	const { translate } = useTranslate();

	const [availableProducts, setAvailableProducts] = useState<SearchItemObj[]>([]);
	const [availableTags, setAvailableTags] = useState<string[]>([]);
	const [itemsShowing, setItemsShowing] = useState<SearchItemObj[]>([]);

	const filterProducts = useCallback((products: Item[]) => {
		const productsToSet: SearchItemObj[] = [];
		products
			.filter((product) => {
				return product.ordering === "Primaire";
			})
			.forEach((product) => {
				productsToSet.push({
					item: product,
					subItems: [],
				});
			});

		products
			.filter((product) => {
				return product.ordering !== "Primaire";
			})
			.forEach((product) => {
				const index = productsToSet.findIndex((p) => p.item.regrouping_id === product.regrouping_id);
				if (index === -1) return;
				productsToSet[index].subItems.push(product);
			});
		setAvailableProducts(productsToSet);
		setItemsShowing(productsToSet);
	}, []);

	useEffect(() => {
		filterProducts(ProductsStore.instance.getProducts());
	}, [filterProducts]);

	useEffect(() => ProductsStore.instance.onChange(filterProducts), [filterProducts]);

	useEffect(() => {
		//get an array of all available tags depending on the language without a reduce
		const tags: string[] = [];
		tags.push(I18nStore.getInstance().lang === "fr" ? "Tout" : "All");
		availableProducts.forEach((product) => {
			const universToSearch = I18nStore.getInstance().lang === "fr" ? product.item.univers_fr : product.item.univers_en;
			if (!tags.includes(universToSearch) && universToSearch !== "") tags.push(universToSearch);
		});

		setAvailableTags(tags);
	}, [availableProducts]);
	const openItem = useCallback((e: React.MouseEvent) => {
		const sku = e.currentTarget.getAttribute("data-sku");
		sku && ItemDrawerStore.instance.openDrawerItem(sku);
	}, []);

	const handleRadioChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.target.value === "Tout" || e.target.value === "All") {
				setItemsShowing(availableProducts);
				return;
			}

			setItemsShowing(
				availableProducts.filter((product) => {
					const universToSearch = I18nStore.getInstance().lang === "fr" ? product.item.univers_fr : product.item.univers_en;
					return e.target.value === universToSearch;
				}),
			);
		},
		[availableProducts],
	);

	const handleSearch = useCallback(
		(search: string) => {
			if (search === "") {
				setItemsShowing(availableProducts);
			} else {
				setItemsShowing(
					availableProducts.filter((product) => {
						const nameToSearch = I18nStore.getInstance().lang === "fr" ? product.item.nom_produit_fr : product.item.nom_produit_en;
						return nameToSearch.toLowerCase().includes(search.toLowerCase());
					}),
				);
			}
		},
		[availableProducts],
	);

	return (
		<div className={classes["root"]}>
			<div className={classes["title"]}>
				<Typography typo={ITypo.DISPLAY_XSMALL}>
					<I18n map={"pages.search.search"} />
				</Typography>
			</div>
			<div className={classes["searchbar"]}>
				<SearchBar onChange={handleSearch} placeholder={translate("components.search.search")} />
			</div>
			<div className={classes["tags-container"]}>
				{availableTags.map((tag) => {
					return (
						<Radio name="tag" value={tag} key={tag} onChange={handleRadioChange} defaultChecked={tag === "Tout" || tag === "All"}>
							{tag}
						</Radio>
					);
				})}
			</div>
			<div className={classes["items"]}>
				{itemsShowing.length > 0 &&
					itemsShowing
						.filter((item) => {
							return item.item.sku !== "canap";
						})
						.map((item) => {
							return <SearchItem item={item} key={item.item.sku} onOpenItem={openItem} />;
						})}
			</div>
		</div>
	);
}
