import React, { useCallback, useEffect, useState } from "react";

import Carousel from "components/materials/Carousel";
import I18n from "components/materials/I18n";
import GoToBasketModal from "components/materials/Modal/GoToBasketModal";
import { useModal } from "hooks/useModal";
import BasketItemsService from "services/BasketItemsService";
import I18nStore from "stores/I18nStore";
import ItemDrawerStore, { DrawerItem } from "stores/ItemDrawerStore";

import Button from "../Button";
import Cross from "../Icons/Cross";
import NumberPicker from "../NumberPicker";
import TabsContainer from "../TabsContainer";
import Typography, { ITypo, ITypoColor } from "../Typography";
import classes from "./classes.module.scss";
import ItemDeclinations from "./ItemDeclinations";

const tabs = [
	I18nStore.getInstance().translate("components.item_drawer.description"),
	I18nStore.getInstance().translate("components.item_drawer.characteristics"),
];

export default function ItemDrawer() {
	const [itemToShow, setItemToShow] = useState<DrawerItem | null>(ItemDrawerStore.instance.getItemInDrawer());
	const [isOpened, setIsOpened] = useState<boolean>(ItemDrawerStore.instance.getIsOpened());
	const [tabOpened, setTabOpened] = useState<string>(tabs[0]);
	const [quantityPicked, setQuantityPicked] = useState<number>(1);
	const { isOpen, closeModal, openModal } = useModal();

	const [touchStart, setTouchStart] = React.useState(0);
	const [touchEnd, setTouchEnd] = React.useState(0);

	const rootRef = React.createRef<HTMLDivElement>();

	useEffect(() => ItemDrawerStore.instance.onItemChange(setItemToShow), []);
	useEffect(() => ItemDrawerStore.instance.onDrawerOpenChange(setIsOpened), []);

	const closeDrawer = () => ItemDrawerStore.instance.closeDrawerItem();

	const addItemToBasket = useCallback(() => {
		if (!itemToShow) return;
		BasketItemsService.instance.addBasketItem(itemToShow.item, quantityPicked);
		openModal();
	}, [itemToShow, openModal, quantityPicked]);

	const onDeclinationChange = useCallback((sku: string) => ItemDrawerStore.instance.setItemInDrawer(sku), []);

	useEffect(() => {
		rootRef.current?.scrollTo(0, 0);
		setQuantityPicked(1);
		setTabOpened(tabs[0]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemToShow]);

	const handleTouchStart = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
		setTouchStart(e.targetTouches[0].clientY);
		setTouchEnd(e.targetTouches[0].clientY);
	}, []);

	const handleTouchMove = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
		setTouchEnd(e.targetTouches[0].clientY);
	}, []);

	const handleTouchEnd = useCallback(
		(e: React.TouchEvent<HTMLDivElement>) => {
			const scrollSensivity = 70;

			if (!rootRef.current || !rootRef.current.scrollTop === undefined) return;
			if (touchStart - touchEnd < -scrollSensivity && rootRef.current.scrollTop === 0) {
				closeDrawer();
			}
		},
		[rootRef, touchEnd, touchStart],
	);
	return (
		<div
			className={classes["root"]}
			data-opened={isOpened.toString()}
			onTouchStart={handleTouchStart}
			onTouchMove={handleTouchMove}
			onTouchEnd={handleTouchEnd}>
			<div className={classes["background-container"]} onClick={closeDrawer} />
			<div className={classes["cross-container"]}>
				<Cross onClick={closeDrawer} />
			</div>
			<div className={classes["drawer"]} ref={rootRef}>
				<div className={classes["carousel-container"]}>{itemToShow && <Carousel item={itemToShow?.item} key={itemToShow.item.sku} />}</div>

				<div className={classes["prices-container"]}>
					<div className={classes["price"]}>
						<Typography typo={ITypo.DISPLAY_SMALL}>{itemToShow?.item.prix.toFixed(2) ?? "-"}</Typography>
						<Typography typo={ITypo.DISPLAY_XSMALL}>€</Typography>
					</div>
				</div>

				<div className={classes["brand"]}>
					<Typography typo={ITypo.TEXT_MD_REGULAR} color={ITypoColor.BLUE_GREEN}>
						{itemToShow?.item.marque ?? "-"}
					</Typography>
				</div>

				<div className={classes["name"]}>
					<Typography typo={ITypo.TEXT_MD_BOLD}>
						{!itemToShow ? "-" : I18nStore.getInstance().lang === "fr" ? itemToShow.item.nom_produit_fr : itemToShow.item.nom_produit_en}
					</Typography>
				</div>

				<div className={classes["declinations"]}>
					<Typography typo={ITypo.TEXT_MD_SEMIBOLD} color={ITypoColor.GREY_STRONG}>
						<I18n map={"components.item_drawer.variant"} />
					</Typography>
				</div>

				<ItemDeclinations
					key={itemToShow?.item.regrouping_id}
					classNames={[classes["declinations-container"]]}
					items={itemToShow?.itemsInGroup ?? []}
					selected={itemToShow?.item.sku ?? "-"}
					onChange={onDeclinationChange}
				/>

				<div className={classes["tabs-container"]}>
					<TabsContainer
						defaultTabs={itemToShow?.item.characteristics_fr === "" ? [tabs[0]] : tabs}
						onChange={setTabOpened}
						key={itemToShow?.item.sku}
					/>
				</div>

				<div className={classes["tab-content"]}>
					{tabOpened === tabs[0] && (
						<div
							className={classes["tab-description"]}
							dangerouslySetInnerHTML={{
								__html:
									I18nStore.getInstance().lang === "fr"
										? (itemToShow?.item.description_fr as string)
										: (itemToShow?.item.description_en as string),
							}}
						/>
					)}
					{tabOpened === tabs[1] && (
						<div
							className={classes["tab-description"]}
							dangerouslySetInnerHTML={{
								__html:
									I18nStore.getInstance().lang === "fr"
										? (itemToShow?.item.characteristics_fr as string)
										: (itemToShow?.item.characteristics_en as string),
							}}
						/>
					)}
				</div>
				<div className={classes["drawer-footer"]} key={itemToShow?.item.sku}>
					<NumberPicker defaultValue={quantityPicked} onChange={setQuantityPicked} fullWidth />
					<Button rightPart={`${(quantityPicked * (itemToShow?.item.prix ?? 0)).toFixed(2)}€`} fullWidth onClick={addItemToBasket}>
						<I18n map={"components.item_drawer.add_to_basket"} />
					</Button>
				</div>
			</div>
			<GoToBasketModal isOpen={isOpen} closeModal={closeModal} closeDrawer={closeDrawer} />
		</div>
	);
}
