import { Item } from "types/Item";
import classes from "./classes.module.scss";
import React, { useCallback, useEffect, useState } from "react";

type IProps = {
	item: Item;
};

enum ItemImage {
	IMAGE_1 = "image_1",
	IMAGE_2 = "image_2",
	IMAGE_3 = "image_3",
	IMAGE_4 = "image_4",
	IMAGE_5 = "image_5",
}

export default function Carousel({ item }: IProps) {
	const [activeImage, setActiveImage] = useState<number>(1);
	const carouselRef = React.createRef<HTMLDivElement>();
	const [touchStart, setTouchStart] = React.useState(0);
	const [touchEnd, setTouchEnd] = React.useState(0);
	const [numberOfImages, setNumberOfImages] = useState<number>(0);

	const onDotClick = useCallback((e: React.MouseEvent) => {
		const i = parseInt(e.currentTarget.getAttribute("data-index") ?? "1");
		setActiveImage(i);
	}, []);

	const getDots = useCallback(
		(item: Item) => {
			const length = item.image_5 ? 5 : item.image_4 ? 4 : item.image_3 ? 3 : item.image_2 ? 2 : 1;
			return (
				<div className={classes["dots-container"]}>
					{Array.from({ length }, (_, i) => i + 1).map((i) => (
						<div className={classes["dot"]} key={i} data-active={(i === activeImage).toString()} data-index={i} onClick={onDotClick} />
					))}
				</div>
			);
		},
		[activeImage, onDotClick],
	);

	const handleTouchStart = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
		setTouchStart(e.targetTouches[0].clientX);
		setTouchEnd(e.targetTouches[0].clientX);
	}, []);

	const handleTouchMove = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
		setTouchEnd(e.targetTouches[0].clientX);
	}, []);

	const handleTouchEnd = useCallback(() => {
		const scrollSensivity = 50;
		if (touchStart - touchEnd > scrollSensivity) {
			if (activeImage === numberOfImages) {
				setActiveImage(1);
				return;
			}
			setActiveImage(activeImage + 1);
		}

		if (touchStart - touchEnd < -scrollSensivity) {
			if (activeImage === 1) {
				setActiveImage(numberOfImages);
				return;
			}
			setActiveImage(activeImage - 1);
		}
	}, [activeImage, numberOfImages, touchEnd, touchStart]);

	useEffect(() => {
		if (item.image_5) setNumberOfImages(5);
		else if (item.image_4) setNumberOfImages(4);
		else if (item.image_3) setNumberOfImages(3);
		else if (item.image_2) setNumberOfImages(2);
		else setNumberOfImages(1);
	}, [item]);

	useEffect(() => {
		if (!carouselRef.current) return;
		carouselRef.current.scrollTo({
			left: (activeImage - 1) * carouselRef.current.clientWidth,
			behavior: "smooth",
		});
	}, [activeImage, carouselRef]);

	return (
		<div className={classes["root"]}>
			<div className={classes["images"]} ref={carouselRef} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
				{getImageFromItemToShow(item, ItemImage.IMAGE_1)}
				{getImageFromItemToShow(item, ItemImage.IMAGE_2)}
				{getImageFromItemToShow(item, ItemImage.IMAGE_3)}
				{getImageFromItemToShow(item, ItemImage.IMAGE_4)}
				{getImageFromItemToShow(item, ItemImage.IMAGE_5)}
			</div>
			{getDots(item)}
		</div>
	);
}

function getImageFromItemToShow(item: Item, imageNum: ItemImage) {
	return (
		item[imageNum] && (
			<div className={classes["image-container"]}>
				<img src={item[imageNum]} alt={imageNum} className={classes["image"]} />
			</div>
		)
	);
}
