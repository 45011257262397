import { useCallback } from "react";
import Typography, { ITypo, ITypoColor } from "../Typography";
import classes from "./classes.module.scss";

export enum EButtonTheme {
	PRIMARY = "primary",
	EMPTY = "empty",
}

export enum EButtonSize {
	SMALL = "small",
	MEDIUM = "medium",
}

type IProps = {
	onClick?: () => void;
	children: React.ReactNode;
	size?: EButtonSize;
	theme?: EButtonTheme;
	rightPart?: React.ReactNode;
	fullWidth?: boolean;
};

export default function Button({ onClick, children, size = EButtonSize.MEDIUM, theme = EButtonTheme.PRIMARY, rightPart, fullWidth = false }: IProps) {
	const renderElement = useCallback(
		(element: React.ReactNode) => {
			return (
				<Typography
					typo={size === EButtonSize.MEDIUM ? ITypo.TEXT_LG_SEMIBOLD : ITypo.TEXT_MD_REGULAR}
					color={theme === EButtonTheme.PRIMARY ? ITypoColor.WHITE : ITypoColor.BLUE_GREEN}>
					{element}
				</Typography>
			);
		},
		[size, theme],
	);
	return (
		<div className={classes["root"]} onClick={onClick} data-size={size} data-theme={theme} data-fullwidth={fullWidth.toString()}>
			<div className={classes["left-part"]}>{renderElement(children)}</div>
			{rightPart && <div className={classes["right-part"]}>{renderElement(rightPart)}</div>}
		</div>
	);
}
