import React from "react";
import classes from "./classes.module.scss";
import classNames from "classnames";

type IProps = {
	typo: ITypo;
	children: React.ReactNode;
	color?: ITypoColor;
	className?: string;
	title?: string;
	onClick?: () => void;
};

export enum ITypo {
	DISPLAY_SMALL = "display-small",
	DISPLAY_XSMALL = "display-xsmall",
	TEXT_LG_BOLD = "text-lg-bold",
	TEXT_LG_SEMIBOLD = "text-lg-semibold",
	TEXT_LG_MEDIUM = "text-lg-medium",
	TEXT_LG_REGULAR = "text-lg-regular",
	TEXT_MD_BOLD = "text-md-bold",
	TEXT_MD_SEMIBOLD = "text-md-semibold",
	TEXT_MD_MEDIUM = "text-md-medium",
	TEXT_MD_REGULAR = "text-md-regular",
	TEXT_SM_SEMIBOLD = "text-sm-semibold",
	TEXT_SM_MEDIUM = "text-sm-medium",
	TEXT_SM_REGULAR = "text-sm-regular",
	TEXT_XS_REGULAR = "text-xs-regular",
}

export enum ITypoColor {
	BLACK = "--color-generic-black",
	WHITE = "--color-generic-white",
	BLUE_GREEN = "--color-primary-blue-green",
	GREY_SOFT = "--color-neutral-grey-soft",
	GREY_STRONG = "--color-neutral-grey-strong",
	GREEN = "--color-alert-green",
	RED = "--color-alert-red",
}

export default function Typography(props: IProps) {
	return (
		<div
			className={classNames(classes["root"], classes[props.typo], props.className ?? "")}
			style={{ color: `var(${props.color})` }}
			title={props.title}
			onClick={props.onClick}>
			{props.children}
		</div>
	);
}
