import { useCallback, useEffect, useState } from "react";
import classes from "./classes.module.scss";
import Minus from "../Icons/Minus";
import Plus from "../Icons/Plus";
import Typography, { ITypo, ITypoColor } from "../Typography";

type IProps = {
	onChange?: (value: number) => void;
	fullWidth?: boolean;
	defaultValue?: number;
};
export default function NumberPicker({ onChange, fullWidth = false, defaultValue = 1 }: IProps) {
	const [value, setValue] = useState(defaultValue);

	const addOne = useCallback(() => {
		setValue(value + 1);
		onChange && onChange(value + 1);
		window.navigator.vibrate && window.navigator.vibrate(50);
	}, [onChange, value]);

	const removeOne = useCallback(() => {
		if (value === 1) {
			setValue(1);
			return;
		}

		window.navigator.vibrate && window.navigator.vibrate(50);
		setValue(value - 1);
		onChange && onChange(value - 1);
	}, [onChange, value]);

	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	return (
		<div className={classes["root"]} data-fullwidth={fullWidth.toString()}>
			<div className={classes["pointer"]}>
				<Minus onClick={removeOne} />
			</div>
			<div className={classes["value"]}>
				<Typography typo={ITypo.TEXT_LG_SEMIBOLD} color={ITypoColor.BLUE_GREEN}>
					{value}
				</Typography>
			</div>
			<div className={classes["pointer"]}>
				<Plus onClick={addOne} />
			</div>
		</div>
	);
}
