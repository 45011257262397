"use client";

import EventEmitter from "services/EventEmitter";
import { Item } from "types/Item";
import ProductsStore from "./ProductsStore";
import HistoryService from "services/HistoryService";

export type DrawerItem = {
	item: Item;
	itemsInGroup: Item[];
};
export default class ItemDrawerStore {
	public static readonly instance = new this();
	protected readonly event = new EventEmitter();
	private itemInDrawer: DrawerItem | null = null;

	private allItems: Item[] = ProductsStore.instance.getProducts();
	private isDrawerOpened: boolean = false;

	private canOpen = true;

	private constructor() {
		ProductsStore.instance.onChange((products) => {
			this.allItems = products;
		});
	}

	public getIsOpened() {
		return this.isDrawerOpened;
	}

	public getItemInDrawer() {
		return this.itemInDrawer;
	}

	public setItemInDrawer(sku: string) {
		const item = this.allItems.find((item) => item.sku === sku) || null;

		if (item === null) {
			return;
		}

		HistoryService.instance.add(item, "catalog");
		const itemsInGroup = this.allItems.filter((itemToFilter) => itemToFilter.regrouping_id === item.regrouping_id);
		const itemInDrawer = (this.itemInDrawer = {
			item,
			itemsInGroup,
		});

		this.event.emit("itemChange", itemInDrawer);
	}

	public openDrawerItem(sku: string) {
		if (!this.canOpen) return;
		this.setItemInDrawer(sku);
		this.isDrawerOpened = true;
		document.body.style.overflow = "hidden";
		this.event.emit("isDrawerOpenedChange", true);
	}

	public closeDrawerItem() {
		this.isDrawerOpened = false;
		this.canOpen = false;

		setTimeout(() => {
			document.body.style.overflow = "auto";
			this.canOpen = true;
		}, 300);

		this.event.emit("isDrawerOpenedChange", false);
	}

	public onItemChange(callback: (itemInDrawer: DrawerItem) => void) {
		this.event.on("itemChange", callback);
		return () => {
			this.event.off("itemChange", callback);
		};
	}

	public onDrawerOpenChange(callback: (isDrawerOpened: boolean) => void) {
		this.event.on("isDrawerOpenedChange", callback);
		return () => {
			this.event.off("isDrawerOpenedChange", callback);
		};
	}
}
