import I18nStore from "stores/I18nStore";
import Modal from "..";
import Button, { EButtonTheme } from "components/design_system/Button";
import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import ModuleConfig from "configs/Module";

type IProps = {
	isOpen: boolean;
	closeModal: () => void;
	closeDrawer?: () => void;
};

export default function GoToBasketModal(props: IProps) {
	const { isOpen, closeModal, closeDrawer } = props;
	const navigate = useNavigate();

	const goToBasket = useCallback(() => {
		navigate(ModuleConfig.getInstance().get().modules.pages.Basket.props.path);
		closeDrawer && closeDrawer();
		closeModal();
	}, [navigate, closeDrawer, closeModal]);

	const scanOtherProducts = useCallback(() => {
		navigate(ModuleConfig.getInstance().get().modules.pages.Scan.props.path);
		closeDrawer && closeDrawer();
		closeModal();
	}, [closeDrawer, closeModal, navigate]);

	return (
		<Modal title={I18nStore.getInstance().translate("modals.continue_or_go")} closeModal={closeModal} isOpen={isOpen}>
			<div className={classes["modal-buttons-container"]}>
				<Button fullWidth onClick={goToBasket}>
					<I18n map={"modals.go_to_basket"} />
				</Button>
				<Button fullWidth theme={EButtonTheme.EMPTY} onClick={scanOtherProducts}>
					<I18n map={"modals.scan_other_products"} />
				</Button>
			</div>
		</Modal>
	);
}
