import { useCallback, useEffect, useRef } from "react";
import classes from "./classes.module.scss";
import Typography, { ITypo, ITypoColor } from "components/design_system/Typography";

import { useLocation, useNavigate } from "react-router-dom";

type INavTab = {
	label: string | JSX.Element;
	link: string;
	icon: JSX.Element;
	number?: number;
};

type IProps = {
	tab: INavTab;
	onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
	onLocated: (element: HTMLDivElement) => void;
};
export default function NavLink({ tab, onClick, onLocated }: IProps) {
	const ref = useRef<HTMLDivElement>(null);
	const location = useLocation();
	const navigate = useNavigate();
	const handleClick = useCallback(
		(e: React.MouseEvent<HTMLDivElement>) => {
			onClick(e);
			navigate(tab.link);
		},
		[navigate, onClick, tab.link],
	);

	useEffect(() => {
		if (tab.link === location.pathname && ref.current) onLocated(ref.current);
	}, [location, onLocated, tab.link]);

	return (
		<div className={classes["root"]} data-link={tab.link} onClick={handleClick} ref={ref}>
			<div className={classes["icon"]}>
				{tab.icon}
				{tab.number && <div className={classes["number"]}>{tab.number}</div>}
			</div>
			<div className={classes["label"]}>
				<Typography typo={ITypo.TEXT_XS_REGULAR} color={ITypoColor.BLACK}>
					{tab.label}
				</Typography>
			</div>
		</div>
	);
}
