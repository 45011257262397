import { useWindowSize } from "@uidotdev/usehooks";
import React, { useCallback, useEffect, useMemo } from "react";
import LangSwitcherButton from "./LangSwitcherButton";
import classes from "./classes.module.scss";

import { ELang } from "stores/I18nStore";
import { useTranslate } from "hooks/useTranslate";


export type ILangTab = {
	lang: ELang;
};

export default function LangSwitcher() {
	const activeNavTabElementRef = React.useRef<HTMLDivElement>();
	const { toggleTo, lang } = useTranslate();
	const size = useWindowSize();

	const update = useCallback((activeNavTabElement: HTMLDivElement, delay: number) => {
		const parentElement = activeNavTabElement.parentElement!;
		const x = activeNavTabElement.getBoundingClientRect().x - parentElement!.getBoundingClientRect().x;
		parentElement!.style.setProperty("--animation-delay", `${delay}ms`);
		parentElement!.style.setProperty("--position-x", `${x}px`);
	}, []);

	const onClick = useCallback(
		(e: React.MouseEvent<HTMLDivElement>) => {
			toggleTo(e.currentTarget.getAttribute("data-link") as ELang);
			(activeNavTabElementRef.current as any) = e.currentTarget;
			if(activeNavTabElementRef.current) update(activeNavTabElementRef.current, 250);
		},
		[toggleTo, update],
	);

	useEffect(() => {
		if (!activeNavTabElementRef.current) return;
		update(activeNavTabElementRef.current, 0);
	}, [size, update, lang]);

	const onLoad = useCallback((element: HTMLDivElement|null) => {
		if (!element) return;
		if(element.getAttribute("data-link") === lang) activeNavTabElementRef.current = element;
	}, [lang]);

	const tabs: JSX.Element[] = useMemo(
		() => [
			<LangSwitcherButton key={ELang.FR} lang={ELang.FR} onClick={onClick} onLoad={onLoad}/>,
			<LangSwitcherButton key={ELang.EN} lang={ELang.EN} onClick={onClick} onLoad={onLoad}/>,
		],
		[onClick, onLoad],
	);

	return (
		<div className={classes["root"]}>
			<div className={classes["links"]}>
				<div className={classes["cursor"]}>
					<div />
				</div>
				{tabs}
			</div>
		</div>
	);
}
