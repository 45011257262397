import { DetectedObject } from "components/pages/Scan";
import classes from "./classes.module.scss";
import React, { useCallback } from "react";
import Typography, { ITypo, ITypoColor } from "components/design_system/Typography";
import I18nStore from "stores/I18nStore";
import ArrowLongRight from "components/design_system/Icons/ArrowLongRight";
import I18n from "components/materials/I18n";
import ItemDrawerStore from "stores/ItemDrawerStore";

type IProps = {
	detectedObject: DetectedObject;
	onCloseTooltip: () => void;
};

export default function Tooltip({ detectedObject, onCloseTooltip }: IProps) {
	const openDrawerItem = useCallback(() => {
		ItemDrawerStore.instance.openDrawerItem(detectedObject.item.sku);
	}, [detectedObject.item.sku]);

	return (
		<div className={classes["root"]} onClick={onCloseTooltip}>
			<div className={classes["tooltip"]}>
				<div className={classes["description-container"]} onClick={openDrawerItem}>
					<Typography className={classes["title"]} color={ITypoColor.BLACK} typo={ITypo.TEXT_MD_BOLD}>
						{I18nStore.getInstance().lang === "fr" ? detectedObject.item.nom_produit_fr : detectedObject.item.nom_produit_en}
					</Typography>
					<Typography color={ITypoColor.GREY_STRONG} typo={ITypo.TEXT_MD_BOLD}>
						{detectedObject.item.prix} €
					</Typography>

					<Typography typo={ITypo.TEXT_MD_BOLD} className={classes["description"]}>
						<I18n map={"components.scanObject.view_description"} /> <ArrowLongRight />
					</Typography>
				</div>
			</div>
		</div>
	);
}
