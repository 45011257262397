import classes from "./classes.module.scss";

export default function Loader() {
	return (
		<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes["root"]}>
			<mask id="path-1-inside-1_289_3500" fill="white">
				<path d="M58.993 49.1865C55.5938 54.5253 50.6879 58.7361 44.8955 61.2866C39.1032 63.8371 32.6846 64.6127 26.4515 63.5153C20.2184 62.4179 14.4506 59.4968 9.87762 55.1214C5.30463 50.746 2.1318 45.1129 0.760338 38.9342C-0.611123 32.7556 -0.11961 26.3091 2.17272 20.4098C4.46505 14.5105 8.45524 9.42345 13.6387 5.79193C18.8222 2.16041 24.9661 0.147518 31.2936 0.00779854C37.621 -0.13192 43.8478 1.60781 49.1865 5.00698L46.3635 9.44086C41.9017 6.60003 36.6977 5.14607 31.4096 5.26283C26.1215 5.3796 20.9868 7.06186 16.6547 10.0969C12.3227 13.1319 8.98794 17.3833 7.07215 22.3136C5.15635 27.2438 4.74558 32.6315 5.89176 37.7952C7.03795 42.9589 9.68961 47.6668 13.5114 51.3235C17.3333 54.9802 22.1536 57.4215 27.3629 58.3386C32.5722 59.2557 37.9364 58.6075 42.7773 56.476C47.6182 54.3444 51.7183 50.8253 54.5591 46.3635L58.993 49.1865Z" />
			</mask>
			<path
				d="M58.993 49.1865C55.5938 54.5253 50.6879 58.7361 44.8955 61.2866C39.1032 63.8371 32.6846 64.6127 26.4515 63.5153C20.2184 62.4179 14.4506 59.4968 9.87762 55.1214C5.30463 50.746 2.1318 45.1129 0.760338 38.9342C-0.611123 32.7556 -0.11961 26.3091 2.17272 20.4098C4.46505 14.5105 8.45524 9.42345 13.6387 5.79193C18.8222 2.16041 24.9661 0.147518 31.2936 0.00779854C37.621 -0.13192 43.8478 1.60781 49.1865 5.00698L46.3635 9.44086C41.9017 6.60003 36.6977 5.14607 31.4096 5.26283C26.1215 5.3796 20.9868 7.06186 16.6547 10.0969C12.3227 13.1319 8.98794 17.3833 7.07215 22.3136C5.15635 27.2438 4.74558 32.6315 5.89176 37.7952C7.03795 42.9589 9.68961 47.6668 13.5114 51.3235C17.3333 54.9802 22.1536 57.4215 27.3629 58.3386C32.5722 59.2557 37.9364 58.6075 42.7773 56.476C47.6182 54.3444 51.7183 50.8253 54.5591 46.3635L58.993 49.1865Z"
				stroke="#5D8078"
				strokeWidth="8"
				mask="url(#path-1-inside-1_289_3500)"
			/>
		</svg>
	);
}
