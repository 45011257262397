import { BasketItem } from "types/Item";
import BaseApiService from "../BaseApiService";

type Product = BasketItem;

interface CreateOrderResponse {
	data: {
		id: number;
		attributes: {
			createdAt: string;
			updatedAt: string;
		};
	};
	meta: {};
}

export default class Orders extends BaseApiService {
	private static instance: Orders;
	private baseUrl: string;

	private constructor() {
		super();
		this.baseUrl = process.env["REACT_APP_API_URL"]!.concat("/api/orders");
		Orders.instance = this;
	}

	public static getInstance() {
		if (!Orders.instance) return new this();
		return Orders.instance;
	}

	public async create(products: Product[]): Promise<CreateOrderResponse> {
		const path = this.baseUrl;
		try {
			return await this.postRequest(path, { data: { products } }, { Authorization: `Bearer ${process.env["REACT_APP_API_KEY"]}` });
		} catch (err) {
			return this.onError(err);
		}
	}
}
