import classNames from "classnames";
import { IconProps } from "..";
import classes from "../classes.module.scss";
import { ITypoColor } from "components/design_system/Typography";

export default function Cross({ color = ITypoColor.BLACK, size = "small", className, onClick }: IconProps) {
	return (
		<svg
			width="35"
			height="35"
			viewBox="0 0 35 35"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={classNames(classes["icon"], className)}
			onClick={onClick}>
			<path d="M8.75 26.25L26.25 8.75M8.75 8.75L26.25 26.25" stroke={`var(${color})`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
}
