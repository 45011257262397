import I18n from "components/materials/I18n";
import { HistoryItem as HistoryItemType } from "services/HistoryService";
import I18nStore from "stores/I18nStore";
import ItemDrawerStore from "stores/ItemDrawerStore";

import ArrowLongRight from "../Icons/ArrowLongRight";
import Typography, { ITypo, ITypoColor } from "../Typography";
import classes from "./classes.module.scss";

type IProps = {
	item: HistoryItemType;
};
export default function HistoryItem({ item }: IProps) {
	const openItemDrawer = () => {
		ItemDrawerStore.instance.openDrawerItem(item.sku);
	};
	const date = new Date(item.date);
	return (
		<div className={classes["root"]} onClick={openItemDrawer}>
			<div className={classes["image-container"]}>
				<img src={`products_images/${item.sku}/small.jpg`} alt={I18nStore.getInstance().lang === "fr" ? item.nom_produit_fr : item.nom_produit_en} />
			</div>
			<div className={classes["info-container"]}>
				<div className={classes["top-infos"]}>
					<div className={classes["name-container"]}>
						<Typography typo={ITypo.TEXT_MD_BOLD} className={classes["name-text"]}>
							{I18nStore.getInstance().lang === "fr" ? item.nom_produit_fr : item.nom_produit_en}
						</Typography>
					</div>
					<div className={classes["prices-container"]}>
						<Typography typo={ITypo.TEXT_LG_SEMIBOLD}>{item.prix.toFixed(2)}€</Typography>
						<Typography typo={ITypo.TEXT_SM_REGULAR} color={ITypoColor.GREY_STRONG}>
							le {date.toLocaleDateString() + " à " + date.toLocaleTimeString()}
						</Typography>
					</div>
					<div className={classes["description-container"]}>
						<Typography typo={ITypo.TEXT_MD_BOLD} className={classes["description"]}>
							<I18n map={"components.historyItem.view_description"} /> <ArrowLongRight />
						</Typography>
					</div>
				</div>
			</div>
		</div>
	);
}
