import classNames from "classnames";
import { IconProps } from "..";
import classes from "../classes.module.scss";
import { ITypoColor } from "components/design_system/Typography";

export default function Refresh({ color = ITypoColor.BLACK, className, onClick, size }: IconProps) {
	switch (size) {
		case "large":
			return (
				<svg
					width="38"
					height="38"
					viewBox="0 0 38 38"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className={classNames(classes["icon"], className)}
					onClick={onClick}>
					<g clipPath="url(#clip0_307_955)">
						<path
							d="M25.3694 14.8018H33.2744V14.799M4.72491 31.1038V23.1988M4.72491 23.1988L12.6299 23.1988M4.72491 23.1988L9.76133 28.2384C11.3297 29.8097 13.32 31.0018 15.6186 31.6177C22.587 33.4849 29.7497 29.3495 31.6168 22.3811M6.38202 15.6195C8.2492 8.65109 15.4119 4.51573 22.3803 6.38291C24.6789 6.99882 26.6692 8.19093 28.2376 9.76221L33.2744 14.799M33.2744 6.89685V14.799"
							stroke={`var(${color})`}
							strokeWidth="3.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</g>
					<defs>
						<clipPath id="clip0_307_955">
							<rect width="38" height="38" fill="white" />
						</clipPath>
					</defs>
				</svg>
			);
		case "small":
		case "medium":
		default:
			return (
				<svg
					width="18"
					height="18"
					viewBox="0 0 18 18"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className={classNames(classes["icon"], className)}
					onClick={onClick}>
					<g clipPath="url(#clip0_160_1681)">
						<path
							d="M12.0172 7.01131H15.7617V7.00997M2.23828 14.7333V10.9888M2.23828 10.9888L5.98276 10.9888M2.23828 10.9888L4.62395 13.376C5.36684 14.1203 6.30964 14.685 7.39845 14.9767C10.6993 15.8612 14.0921 13.9023 14.9766 10.6015M3.02323 7.39863C3.90768 4.09781 7.30051 2.13895 10.6013 3.0234C11.6902 3.31515 12.633 3.87984 13.3759 4.62413L15.7617 7.00997M15.7617 3.26685V7.00997"
							stroke={`var(${color})`}
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</g>
					<defs>
						<clipPath id="clip0_160_1681">
							<rect width="18" height="18" fill="white" />
						</clipPath>
					</defs>
				</svg>
			);
	}
}
