import { BasketItem } from "types/Item";
import { HistoryItem } from "./HistoryService";

enum LocalStorageItems {
	basket = "basket",
	history = "history",
	session = "session",
}

export default class LocalStorageService {
	private static instance: LocalStorageService;

	private constructor() {}

	public static getInstance(): LocalStorageService {
		return (this.instance = this.instance ?? new LocalStorageService());
	}

	public items = {
		basket: {
			get: () => this.getValue<BasketItem[]>(LocalStorageItems.basket),
			set: (items: BasketItem[]) => this.setValue(LocalStorageItems.basket, items),
			delete: () => this.delete(LocalStorageItems.basket),
		},
		history: {
			get: () => this.getValue<HistoryItem[]>(LocalStorageItems.history),
			set: (items: HistoryItem[]) => this.setValue(LocalStorageItems.history, items),
			delete: () => this.delete(LocalStorageItems.history),
		},
	};

	public session = {
		get: () => this.getValue<string>(LocalStorageItems.session),
		set: (session: string) => this.setValue(LocalStorageItems.session, session),
		delete: () => this.delete(LocalStorageItems.session),
	};

	private getValue<T>(key: LocalStorageItems): T | null {
		const item = localStorage.getItem(key);
		if (!item) {
			return null;
		}

		const isArrayOrObject = item.match(/[{[]/);

		return (isArrayOrObject ? JSON.parse(item) : item) as T;
	}

	private setValue<T>(key: LocalStorageItems, item: T) {
		localStorage.setItem(key, typeof item === "string" ? item : JSON.stringify(item));
	}

	private delete(key: LocalStorageItems) {
		localStorage.removeItem(key);
	}
}
