import Typography, {
    ITypo,
    ITypoColor,
} from "components/design_system/Typography";
import { Tab } from "..";
import classes from "./classes.module.scss";

type IProps = {
    innerRef: React.RefObject<HTMLDivElement>;
    tab: Tab;
    handleOnClick: (name: string) => void;
};

export default function TabElement({ innerRef, tab, handleOnClick }: IProps) {
    const clickHandler = () => {
        handleOnClick(tab.name);
    };

    return (
        <div
            ref={innerRef}
            className={classes["root"]}
            data-active={tab.active.toString()}
            onClick={clickHandler}
        >
            <Typography
                typo={ITypo.TEXT_LG_REGULAR}
                color={tab.active ? ITypoColor.BLUE_GREEN : ITypoColor.BLACK}
            >
                {tab.name}
            </Typography>
        </div>
    );
}
