import classes from "./classes.module.scss";

import BasketItem from "components/design_system/BasketItem";
import Button, { EButtonSize } from "components/design_system/Button";
import ShoppingBag from "components/design_system/Icons/ShoppingBag";
import Typography, { ITypo, ITypoColor } from "components/design_system/Typography";
import I18n from "components/materials/I18n";
import GoToMidModal from "components/materials/Modal/GoToMidModal";

import ModuleConfig from "configs/Module";
import { useModal } from "hooks/useModal";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BasketItemsService from "services/BasketItemsService";
import { BasketItem as BasketItemType } from "types/Item";

function productsPrice(items: BasketItemType[]) {
	return items.reduce((acc, item) => acc + item.prix * item.quantity, 0);
}

export default function Basket() {
	const { isOpen, openModal, closeModal } = useModal();
	const navigate = useNavigate();
	const [items, setItems] = useState<BasketItemType[]>(BasketItemsService.instance.getBasketItems());

	useEffect(() => {
		const removeListenerCallback = BasketItemsService.instance.onChange((items) => setItems(items));
		return () => {
			removeListenerCallback();
		};
	}, []);
	const handleDeleteItem = useCallback((id: string) => BasketItemsService.instance.removeBasketItem(id), []);

	const goToScanPage = useCallback(() => navigate(ModuleConfig.getInstance().get().modules.pages.Scan.props.path), [navigate]);

	const passOrder = useCallback(async () => {
		try {
			openModal();
		} catch (e) {
			console.log(e);
		}
	}, [openModal]);

	const onItemQuantityChange = useCallback((quantity: number, sku: string) => {
		BasketItemsService.instance.updateBasketItemQuantity(quantity, sku);
	}, []);
	return (
		<div className={classes["root"]}>
			<div className={classes["top-container"]}>
				<Typography typo={ITypo.DISPLAY_XSMALL}>
					<I18n map={"pages.basket.your_basket"} />
				</Typography>
				<div className={classes["separator-prices"]} />
				<div className={classes["total-container"]}>
					<div className={classes["line"]}>
						<div className={classes["left"]}>
							<Typography typo={ITypo.TEXT_LG_REGULAR}>
								<I18n map={"pages.basket.total"} />
							</Typography>
						</div>
						<div className={classes["right"]}>
							<Typography typo={ITypo.DISPLAY_XSMALL} color={ITypoColor.BLUE_GREEN}>
								{items.length === 0 ? "-" : productsPrice(items).toFixed(2)}€
							</Typography>
						</div>
					</div>
				</div>
			</div>
			<div className={classes["content-container"]}>
				{items.length === 0 && (
					<div className={classes["empty-container"]}>
						<ShoppingBag color={ITypoColor.BLUE_GREEN} size="large" />
						<Typography typo={ITypo.DISPLAY_XSMALL}>
							<I18n map={"pages.basket.empty_basket"} />
						</Typography>
						<Typography typo={ITypo.TEXT_MD_REGULAR}>
							<I18n map={"pages.basket.empty_basket_text"} />
						</Typography>
						<div className={classes["button-scan"]}>
							<Button onClick={goToScanPage} fullWidth>
								<I18n map={"pages.basket.scan"} />
							</Button>
						</div>
					</div>
				)}
				{items.length > 0 && (
					<div className={classes["items-container"]}>
						{items.map((item) => {
							return (
								<div className={classes["item"]} key={item.sku}>
									<BasketItem item={item} onDelete={handleDeleteItem} onItemQuantityChange={onItemQuantityChange} />
									<div className={classes["separator"]} />
								</div>
							);
						})}
					</div>
				)}
			</div>
			{items.length > 0 && (
				<div className={classes["button-container"]}>
					<Button size={EButtonSize.MEDIUM} fullWidth onClick={passOrder}>
						<I18n map={"pages.basket.order"} />
					</Button>
				</div>
			)}
			<GoToMidModal closeModal={closeModal} isOpen={isOpen} />
		</div>
	);
}
