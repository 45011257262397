import classes from "./classes.module.scss";
import Typography, { ITypo } from "../Typography";
import React from "react";

type IProps = {
	defaultChecked?: boolean;
	name: string;
	value: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	children: React.ReactNode;
};

export default function Radio(props: IProps) {
	const { name, value, defaultChecked = false, children, onChange } = props;

	return (
		<div className={classes["root"]}>
			<input
				type="radio"
				name={name}
				value={value}
				id={name + value}
				className={classes["radio-button"]}
				onChange={onChange}
				defaultChecked={defaultChecked}
			/>
			<label className={classes["label"]} htmlFor={name + value}>
				<Typography typo={ITypo.TEXT_XS_REGULAR} className={classes["label-typo"]}>
					{children}
				</Typography>
			</label>
		</div>
	);
}
