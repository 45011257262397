import React from "react";
import I18nStore, { ILngType } from "stores/I18nStore";

export function useTranslate() {
	const [, setAsset] = React.useState<ILngType>(I18nStore.getInstance().asset);

	React.useEffect(
		() =>
			I18nStore.getInstance().onChange((asset) => {
				setAsset(asset);
			}),
		[],
	);

	return {
		translate: I18nStore.getInstance().translate.bind(I18nStore.getInstance()),
		toggleTo: I18nStore.getInstance().toggleTo.bind(I18nStore.getInstance()),
		lang: I18nStore.getInstance().lang,
	};
}
