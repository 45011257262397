import { BoundingBox, Detection, FilesetResolver, ObjectDetector, ObjectDetectorResult } from "@mediapipe/tasks-vision";

import { useDebounce, useOrientation, useWindowSize } from "@uidotdev/usehooks";
import React, { useCallback, useEffect, useRef, useState } from "react";
import classes from "./classes.module.scss";

// let lastTime = 0;
let currentFrame = 0;
let lastVideoTime = -1;

// const frameDelay = 400; // Delay in milliseconds
const constancyThreshold = 6;
const distanceThreshold = 15;
const iouThreshold = 0.2;

const model = "v7.tflite";
type RunningMode = "VIDEO" | "IMAGE";

export type IFacingModeType = "user" | "environment";

type IProps = {
	facingMode: IFacingModeType;
	onDetect: (points: Point[]) => void;
	setProgress: React.Dispatch<React.SetStateAction<number>>;
	onReady: React.Dispatch<React.SetStateAction<boolean>>;
	isPaused: boolean;
};

type IVideoSize = {
	width: number;
	height: number;
};

export type Point = {
	id: number;
	boundingBox: BoundingBox;
	name: string;
	frameLastSeen: number;
	x: number;
	y: number;
	score: number;
};

const isIPhone = navigator.userAgent.match(/iPhone/i);
const isAndroid = navigator.userAgent.match(/Android/i);
const isMobile = isIPhone || isAndroid;

function WebcamAi({ facingMode = "environment", onDetect, setProgress, onReady, isPaused }: IProps) {
	const size = useWindowSize();
	const videoRef = useRef<HTMLVideoElement>(null);
	const [objectDetector, setObjectDetector] = useState<ObjectDetector | null>(null);
	const [runningMode] = useState<RunningMode>("VIDEO");
	const [enablePredicating, setEnablePredicating] = useState<boolean>(false);
	const [cameraReady, setCameraReady] = useState<boolean>(false);
	const [camHeight, setCamHeight] = useState<number>(1);
	const [camWidth, setCamWidth] = useState<number>(1);
	const requestAnimationFrameId = useRef<number | null>(null);
	const [videoSize] = useState<IVideoSize>({ width: size.width ?? 0, height: size.height ?? 0 });
	const debouncedResize = useDebounce(size.width && size.height ? size.width.toString().concat(size.height.toString()) : undefined, 300);
	const [points, setPoints] = useState<Point[]>([]);
	const [isModelLoaded, setIsModelLoaded] = useState<boolean>(false);
	const { type: orientationType } = useOrientation();
	videoSize.width = size.width ?? 0;
	videoSize.height = size.height ?? 0;

	if (size.width ?? 0 < (size.height ?? 0)) {
		videoSize.width = size.height ?? 0;
		videoSize.height = size.width ?? 0;
	}

	if (orientationType === "landscape-primary" || orientationType === "landscape-secondary") {
		videoSize.width = size.width ?? 0;
		videoSize.height = size.height ?? 0;
	}

	useEffect(() => {
		preloadTfLiteFile(setProgress, false)
			.then(() => setIsModelLoaded(true))
			.catch((error) => console.error("Erreur lors du chargement du fichier", error));
	}, [setProgress]);

	/**
	 * @description Initialize ObjectDetector at didMount and close it at willUnmount
	 */
	useEffect(() => {
		if (!isModelLoaded) return;
		let currentObjectDetector: ObjectDetector | null = null;
		initializeObjectDetector(runningMode).then(async (objectDetector) => {
			currentObjectDetector = objectDetector;
			await objectDetector.setOptions({ runningMode: runningMode });
			if (!currentObjectDetector) return;
			setObjectDetector(objectDetector);
		});

		return () => {
			currentObjectDetector?.close();
			currentObjectDetector = null;
		};
	}, [runningMode, isModelLoaded]);

	const predictWebcam = useCallback(() => {
		if (!enablePredicating || !videoRef.current || !objectDetector || isPaused) return;
		try {
			if (videoRef.current.currentTime === lastVideoTime) {
				requestAnimationFrameId.current = window.requestAnimationFrame(predictWebcam);
				return;
			}
			currentFrame++;
			lastVideoTime = videoRef.current.currentTime;

			const startTimeMs = performance.now();
			const detections = objectDetector.detectForVideo(videoRef.current, startTimeMs, {});
			const newPoints = createPoints(points, detections, videoSize, camWidth, camHeight);

			setPoints(newPoints);
			onDetect(newPoints);
		} catch (error) {
			console.error(error);
		}

		requestAnimationFrameId.current = window.requestAnimationFrame(predictWebcam);
	}, [enablePredicating, objectDetector, onDetect, points, camHeight, camWidth, videoSize, isPaused]);

	useEffect(() => {
		setEnablePredicating(!!objectDetector && cameraReady);
	}, [objectDetector, cameraReady]);

	/**
	 * @description Predict webcam when isPredicating
	 */
	useEffect(() => {
		if (!enablePredicating) return;
		if (requestAnimationFrameId.current) {
			window.cancelAnimationFrame(requestAnimationFrameId.current);
		}
		requestAnimationFrameId.current = window.requestAnimationFrame(predictWebcam);
	}, [enablePredicating, predictWebcam]);

	/**
	 * @description Ask user to enable webcam at didMount
	 */
	useEffect(() => {
		if (!videoSize.height || !videoSize.width) return;
		const video = videoRef.current;
		let stream: MediaStream | null = null;

		if (isModelLoaded && videoRef.current && debouncedResize) {
			enableCam(videoRef.current, facingMode, videoSize)
				.then((values) => {
					stream = values.stream;
					videoRef.current?.play();
					if (isMobile && values.height > values.width) {
						setCamHeight(values.width);
						setCamWidth(values.height);
						setCameraReady(true);
						onReady(true);
						return;
					}

					setCamHeight(values.height);
					setCamWidth(values.width);
					setCameraReady(true);
					onReady(true);
				})
				.catch((err) => console.info(err));
		}
		return () => {
			if (video) {
				video.pause();
				video.src = "";
			}
			stream?.getTracks().forEach((track) => track.stop());
		};
	}, [videoSize, facingMode, isModelLoaded, debouncedResize, onReady]);

	return (
		<video
			className={classes["video"]}
			ref={videoRef}
			width={size.width?.toString().concat("px")}
			height={size.height?.toString().concat("px")}
			// autoPlay
			playsInline></video>
	);
}

export default React.memo(WebcamAi);

const preloadTfLiteFile = (() => {
	let response: Response | null = null;

	return async (setProgress: (progress: number) => void, reset: boolean) => {
		if (reset) response = null;
		if (response) return response;

		response = await fetch(model);

		if (!response.ok) {
			throw new Error(`Erreur HTTP! Statut : ${response.status}`);
		}

		const contentLength = response.headers.get("Content-Length");
		const totalSize = contentLength ? parseInt(contentLength, 10) : 0;

		if (!response.body) throw new Error("No body found");

		const reader = response.body.getReader();
		let bytesRead = 0;

		while (true) {
			const { done, value } = await reader.read();
			if (done) break;
			bytesRead += value!.byteLength;
			// Calcul du pourcentage de chargement
			const currentProgress = (bytesRead / totalSize) * 100;
			setProgress(currentProgress);
		}
	};
})();

/**
 * @description Initialize ObjectDetector
 */
async function initializeObjectDetector(runningMode: RunningMode) {
	const visionFilesetResolver = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm");
	return ObjectDetector.createFromOptions(visionFilesetResolver, {
		baseOptions: {
			// modelAssetPath: `https://storage.googleapis.com/mediapipe-models/object_detector/efficientdet_lite0/float16/1/efficientdet_lite0.tflite`,
			modelAssetPath: model,
			delegate: "GPU",
		},
		scoreThreshold: 0.7,
		runningMode: runningMode,
	});
}

/**
 * @description Ask for user's webcam
 */
async function enableCam(video: HTMLVideoElement, facingMode: IFacingModeType = "environment", videoSize: IVideoSize, reverse = false) {
	let existingStream: MediaStream | null = null;

	if (existingStream) {
		video.pause();
		video.src = "";
		(existingStream as MediaStream).getTracks().forEach((track) => track.stop());
	}
	return new Promise<{ width: number; height: number; stream: MediaStream }>((resolve, reject) => {
		const aspectRatio = videoSize.width / videoSize.height;

		navigator.mediaDevices
			.getUserMedia({
				video: {
					facingMode,
					aspectRatio: {
						exact: aspectRatio,
					},
				},
				audio: false,
			})
			.then(function (stream) {
				existingStream = stream;
				const trackSettings = stream.getVideoTracks()[0].getSettings();
				const width = trackSettings.width;
				const height = trackSettings.height;
				if (!width || !height) return;
				video.srcObject = stream;
				const onLoadeddata = () => {
					video.removeEventListener("loadeddata", onLoadeddata);
					resolve({ width, height, stream });
				};
				video.addEventListener("loadeddata", onLoadeddata);
			})
			.catch((err) => reject(err));
	});
}

function matchObject(existingPoints: Point[], detected: Detection): { id: number | null; index: number | null } {
	let bestMatchId: number | null = null;
	let index: number | null = null;
	let highestIoU = 0;
	if (!detected.boundingBox) return { id: null, index: null };

	const boundingBox = detected.boundingBox;

	existingPoints.forEach((point, i) => {
		const currentIoU = calculateIoU(point.boundingBox, boundingBox);

		if (currentIoU > highestIoU && currentIoU >= iouThreshold) {
			highestIoU = currentIoU;
			bestMatchId = point.id;
			index = i;
		}
	});

	return { id: bestMatchId, index };
}

function calculateIoU(bbox1: BoundingBox, bbox2: BoundingBox): number {
	const box1 = {
		x1: bbox1.originX,
		y1: bbox1.originY,
		x2: bbox1.originX + bbox1.width,
		y2: bbox1.originY + bbox1.height,
	};
	const box2 = {
		x1: bbox2.originX,
		y1: bbox2.originY,
		x2: bbox2.originX + bbox2.width,
		y2: bbox2.originY + bbox2.height,
	};

	const x_overlap = Math.max(0, Math.min(box1.x2, box2.x2) - Math.max(box1.x1, box2.x1));
	const y_overlap = Math.max(0, Math.min(box1.y2, box2.y2) - Math.max(box1.y1, box2.y1));
	const overlapArea = x_overlap * y_overlap;

	const area1 = bbox1.width * bbox1.height;
	const area2 = bbox2.width * bbox2.height;
	const unionArea = area1 + area2 - overlapArea;

	return overlapArea / unionArea;
}

const createPoints = (() => {
	let nextObjectId = 0;

	return (existingPoints: Point[], detection: ObjectDetectorResult, videoSize: IVideoSize, camWidth: number, camHeight: number) => {
		// Track which points are currently detected
		const newPoints = [...existingPoints];

		detection.detections.forEach((detected) => {
			const categories = detected.categories.sort((a, b) => b.score - a.score);
			if (!detected.boundingBox) return;
			const highestCategory = categories[0];

			const centerX = detected.boundingBox.originX + detected.boundingBox.width / 2;
			const centerY = detected.boundingBox.originY + detected.boundingBox.height / 2;
			let x = (centerX / camWidth) * videoSize.width;
			let y = (centerY / camHeight) * videoSize.height;

			const { index } = matchObject(newPoints, detected);

			if (index === null) {
				const newId = nextObjectId++;

				newPoints.push({
					id: newId,
					boundingBox: detected.boundingBox,
					frameLastSeen: currentFrame,
					name: highestCategory.categoryName,
					x,
					y,
					score: highestCategory.score,
				});
				return;
			}
			let existingPoint = { ...newPoints[index] };
			const previousX = existingPoint.x;
			const previousY = existingPoint.y;

			const distance = Math.sqrt(Math.pow(previousX - x, 2) + Math.pow(previousY - y, 2));
			const maxDistance = detected.boundingBox.width / 2;

			if (highestCategory.categoryName !== existingPoints[index].name && highestCategory.score > existingPoint.score) {
				existingPoint.name = highestCategory.categoryName;
				existingPoint.score = highestCategory.score;
			}
			if (distance > Math.max(maxDistance, distanceThreshold)) {
				existingPoint.x = x;
				existingPoint.y = y;
			}
			existingPoint.frameLastSeen = currentFrame;
			existingPoint.boundingBox = detected.boundingBox;

			newPoints[index] = existingPoint;
		});

		return newPoints.filter((point) => currentFrame - point.frameLastSeen <= constancyThreshold);
	};
})();
