import SearchBar from "components/design_system/SearchBar";
import classes from "./classes.module.scss";

import Typography, { ITypo, ITypoColor } from "components/design_system/Typography";
import Book from "components/design_system/Icons/Book";
import Button from "components/design_system/Button";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import ModuleConfig from "configs/Module";
import I18n from "components/materials/I18n";
import { useTranslate } from "hooks/useTranslate";
import I18nStore from "stores/I18nStore";
import HistoryService, { HistoryItem as HistoryItemType } from "services/HistoryService";
import HistoryItem from "components/design_system/HistoryItem";

export default function History() {
	const { translate } = useTranslate();
	const navigate = useNavigate();
	const [items, setItems] = useState<HistoryItemType[]>(HistoryService.instance.get());

	const [itemsShowing, setItemsShowing] = useState<HistoryItemType[]>([]);

	useEffect(() => {
		setItemsShowing(HistoryService.instance.get());
		const removeListenerCallback = HistoryService.instance.onChange((items) => setItems(items));
		return () => {
			removeListenerCallback();
		};
	}, []);

	const goToScanPage = useCallback(() => navigate(ModuleConfig.getInstance().get().modules.pages.Scan.props.path), [navigate]);

	const handleSearch = useCallback(
		(search: string) => {
			if (search === "") {
				setItemsShowing(items);
			} else {
				setItemsShowing(
					items.filter((item) => {
						const nameToSearch = I18nStore.getInstance().lang === "fr" ? item.nom_produit_fr : item.nom_produit_en;
						return nameToSearch.toLowerCase().includes(search.toLowerCase());
					}),
				);
			}
		},
		[items],
	);

	return (
		<div className={classes["root"]}>
			<div className={classes["title"]}>
				<Typography typo={ITypo.DISPLAY_XSMALL}>
					<I18n map={"pages.history.title"} />
				</Typography>
			</div>
			<div className={classes["searchbar"]}>
				<SearchBar onChange={handleSearch} placeholder={translate("components.search.search")} />
			</div>
			<div className={classes["history"]}>
				{itemsShowing.length > 0 &&
					itemsShowing.map((item) => {
						return (
							<div className={classes["item"]} key={item.sku + item.date}>
								<HistoryItem item={item} />
								<div className={classes["separator"]} />
							</div>
						);
					})}
				{items.length === 0 && (
					<div className={classes["empty-container"]}>
						<Book color={ITypoColor.BLUE_GREEN} size="large" />
						<Typography typo={ITypo.DISPLAY_XSMALL}>
							<I18n map={"pages.history.empty_history"} />
						</Typography>
						<Typography typo={ITypo.TEXT_MD_REGULAR}>
							<I18n map={"pages.history.empty_history_text"} />
						</Typography>
						<div className={classes["button-scan"]}>
							<Button onClick={goToScanPage} fullWidth>
								<I18n map={"pages.history.scan"} />
							</Button>
						</div>
					</div>
				)}
				{items.length > 0 && itemsShowing.length === 0 && (
					<div className={classes["not-found-container"]}>
						<Typography typo={ITypo.TEXT_MD_REGULAR} color={ITypoColor.GREY_STRONG}>
							<I18n map={"pages.history.no_correspondance"} />
						</Typography>
					</div>
				)}
			</div>
		</div>
	);
}
