import Glass from "../Icons/Glass";
import classes from "./classes.module.scss";

type IProps = {
    onChange: (value: string) => void;
    placeholder?: string;
};

export default function SearchBar({ onChange, placeholder = "" }: IProps) {
    return (
        <div className={classes["root"]}>
            <input
                className={classes["input"]}
                type="text"
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value)}
            />
            <Glass className={classes["icon"]} />
        </div>
    );
}
