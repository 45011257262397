import React, { useEffect } from "react";

import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import Navbar from "components/design_system/Navbar";
import { Outlet, useLocation } from "react-router-dom";
import ItemDrawer from "components/design_system/ItemDrawer";

type IProps = {
	title?: string;
	children?: React.ReactNode;
	className?: string;
	padding?: boolean;
};

export default function DefaultTemplate(props: IProps) {
	const location = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
		window.document.title = props.title ?? "Accor IA";
	}, [location, props.title]);

	return (
		<I18n>
			<main className={classes["main"]}>
				<Outlet />
			</main>
			<header className={classes["navbar"]}>
				<Navbar />
			</header>
			<ItemDrawer />
		</I18n>
	);
}
