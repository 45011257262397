import I18nStore from "stores/I18nStore";
import Modal from "..";
import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import { useEffect, useState } from "react";
import Loader from "components/elements/Loader";
import LogoMid from "./logo_mid.png";
import Typography, { ITypo } from "components/design_system/Typography";
import { BasketItem } from "types/Item";
import BasketItemsService from "services/BasketItemsService";
import Orders from "services/api/Orders";

type IProps = {
	isOpen: boolean;
	closeModal: () => void;
};

export default function GoToMidModal(props: IProps) {
	const { isOpen, closeModal } = props;
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		let checkoutURL = generateCheckoutURL(BasketItemsService.instance.getBasketItems());
		if (isOpen) {
			setIsLoading(true);
			setTimeout(async () => {
				if (BasketItemsService.instance.getBasketItems().length) {
					await Orders.getInstance().create(BasketItemsService.instance.getBasketItems());
				}
				setIsLoading(false);

				setTimeout(() => {
					BasketItemsService.instance.clearBasketItems();
					closeModal();
					window.location.href = checkoutURL;
				}, 1000);
			}, 1500);
		}
	}, [closeModal, isOpen]);

	

	return (
		<Modal title={I18nStore.getInstance().translate("modals.redirect_to_partner")} closeModal={closeModal} isOpen={isOpen} closable={false}>
			<div className={classes["modal-buttons-container"]}>
				<img src={LogoMid} alt="Logo Mid" className={classes["logo-mid"]} />
				{isLoading && (
					<div className={classes["loader-container"]}>
						<Loader />
						<Typography typo={ITypo.TEXT_MD_REGULAR}>
							<I18n map={"modals.calculate_cart"} />
						</Typography>
					</div>
				)}
				{!isLoading && (
					<div className={classes["loader-container"]}>
						<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="32" cy="32" r="32" fill="#5D8078" />
							<g clipPath="url(#clip0_289_3553)">
								<path d="M22 33L30 41L42 23" stroke="#F2F2F2" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
							</g>
							<defs>
								<clipPath id="clip0_289_3553">
									<rect width="32" height="32" fill="white" transform="translate(16 16)" />
								</clipPath>
							</defs>
						</svg>

						<Typography typo={ITypo.TEXT_MD_REGULAR}>
							<I18n map={"modals.redirect"} />
						</Typography>
					</div>
				)}
			</div>
		</Modal>
	);
}

function generateCheckoutURL(itemsArray: BasketItem[]): string {
	// Initialize an empty array to store item strings
	const itemStrings: string[] = [];

	// Iterate through the items in the array
	itemsArray.forEach((item) => {
		const sku = item.sku;
		const quantity = item.quantity;

		// Construct the item string in the format "sku|quantity"
		const itemString = `${sku}|${quantity}`;

		// Push the item string to the array
		itemStrings.push(itemString);
	});

	// Join the item strings with a semicolon and add them to the URL
	const itemsURL = itemStrings.join(";");
	const checkoutURL = I18nStore.getInstance().translate("madeInDesignUrl").concat(`/checkout/cart/addmultiple/productSku/${itemsURL}/accor/1`);

	return checkoutURL;
}
