import { useWindowSize } from "@uidotdev/usehooks";
import ModuleConfig from "configs/Module";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Book from "../Icons/Book";
import Camera from "../Icons/Camera";
import Glass from "../Icons/Glass";
import ShoppingBag from "../Icons/ShoppingBag";
import NavLink from "./NavLink";
import classes from "./classes.module.scss";

import I18n from "components/materials/I18n";
import BasketItemsService from "services/BasketItemsService";
import { Item } from "types/Item";

export type INavTabs = JSX.Element[];

const pagesModule = ModuleConfig.getInstance().get().modules.pages;

export default function Navbar() {
	const activeNavTabElementRef = React.useRef<HTMLDivElement>();
	const size = useWindowSize();


	const [items, setItems] = useState<Item[]>(BasketItemsService.instance.getBasketItems());

	useEffect(() => {
		const removeListenerCallback = BasketItemsService.instance.onChange((items) => setItems(items));
		return () => {
			removeListenerCallback();
		};
	}, []);

	const update = useCallback((activeNavTabElement: HTMLDivElement, delay: number) => {
		const parentElement = activeNavTabElement.parentElement!;
		const x = activeNavTabElement.getBoundingClientRect().x - parentElement!.getBoundingClientRect().x;
		parentElement!.style.setProperty("--animation-delay", `${delay}ms`);
		parentElement!.style.setProperty("--position-x", `${x}px`);
	}, []);

	const onClick = useCallback(
		(e: React.MouseEvent<HTMLDivElement>) => {
			activeNavTabElementRef.current = e.currentTarget;
			update(activeNavTabElementRef.current, 250);
		},
		[update],
	);

	const onLocated = useCallback(
		(element: HTMLDivElement) => {
			activeNavTabElementRef.current = element;
			update(activeNavTabElementRef.current, 0);
		},
		[update],
	);

	useEffect(() => {
		if (!activeNavTabElementRef.current) return;
		update(activeNavTabElementRef.current, 0);
	}, [size, update]);

	const tabs: INavTabs = useMemo(
		() => [
			<NavLink
				key={pagesModule.Scan.props.path}
				tab={{ icon: <Camera />, label: <I18n map={pagesModule.Scan.props.labelKey} />, link: pagesModule.Scan.props.path }}
				onClick={onClick}
				onLocated={onLocated}
			/>,

			<NavLink
				key={pagesModule.History.props.path}
				tab={{
					icon: <Book />,
					label: <I18n map={pagesModule.History.props.labelKey} />,
					link: pagesModule.History.props.path,
				}}
				onClick={onClick}
				onLocated={onLocated}
			/>,

			<NavLink
				key={pagesModule.Basket.props.path}
				tab={{
					icon: <ShoppingBag />,
					label: <I18n map={pagesModule.Basket.props.labelKey} />,
					link: pagesModule.Basket.props.path,
					number: items.length,
				}}
				onClick={onClick}
				onLocated={onLocated}
			/>,

			<NavLink
				key={pagesModule.Search.props.path}
				tab={{
					icon: <Glass />,
					label: <I18n map={pagesModule.Search.props.labelKey} />,
					link: pagesModule.Search.props.path,
				}}
				onClick={onClick}
				onLocated={onLocated}
			/>,
		],
		[items.length, onClick, onLocated],
	);

	return (
		<div className={classes["root"]}>
			<div className={classes["links"]}>
				<div className={classes["cursor"]}>
					<div />
				</div>
				{tabs.map((tab) => {
					return tab;
				})}
			</div>
		</div>
	);
}
