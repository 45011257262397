import Typography, { ITypo, ITypoColor } from "components/design_system/Typography";
import classes from "./classes.module.scss";

import Refresh from "components/design_system/Icons/Refresh";

export default function WrongOrientation() {
	return (
		<div className={classes["root"]}>
			<div className={classes["container"]}>
				<div className={classes["text-container"]}>
					<Refresh size="large" color={ITypoColor.BLUE_GREEN} />
					<div className={classes["title"]}>
						<Typography typo={ITypo.DISPLAY_SMALL}>Retournez votre écran</Typography>
						<Typography typo={ITypo.TEXT_MD_REGULAR}>
							Vous devez changer l'orientation de votre appareil pour profiter d'une meilleure expérience
						</Typography>
					</div>
				</div>
			</div>
		</div>
	);
}
